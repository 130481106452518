<template>
  <div>

    <CoolLightBox
      :items="photos"
      :index="index"
      :useZoomBar="true"
      @close="index = null"
    ></CoolLightBox>

    <section id="about" class="section-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2>Aplicação de Insulfilm em Janelas</h2>

            <p>
              Devido a incidência do sol, não vale a pena perder o panorama
              emocionante dos ambientes onde possui janelas, vidros, sacadas,
              divisórias.
            </p>

            <p>
              Uma das soluções é aplicar uma boa película de insulfilm em
              janelas. Além de tornar espetacular a aparência de áreas onde
              possui janelas, os problemas causados pela visibilidade,
              temperatura e luz ultravioleta desaparecerão!
            </p>

            <p>
              O insulfim também pode ser usado para proteção, decoração e
              privacidade dos vidros em geral.
            </p>

            <h3>Benefícios do insulfilm para janela</h3>

            <ul class="list-disc">
              <li>Proteção solar e redução de calor</li>
              <li>Aumento da resistência dos vidros</li>
              <li>Proteção de objetos do sol</li>
              <li>Economia com o ar condicionado</li>
              <li>Modernização de fachadas</li>
              <li>Facilidade de limpeza</li>
            </ul>

            
            <h3>Insulfilm de proteção solar para janela</h3>
            <p>
              O <router-link :to="{ name : 'insulfilmProtecaoSolarJanela' }" title="insulfilm de proteção solar para janela">insulfilm de proteção solar para janela</router-link>, reúne características
              fundamentais para a perfeita climatização de ambientes, com ganhos
              expressivos em economia, privacidade e conforto.
            </p>
            <div @click="index = 0" class="page-photo-container">
              <Photo
                :src="photos[0].thumb"
                :title="photos[0].title"
                :description="photos[0].description"
                :idLocation="photos[0].idLocation"
              />
            </div>

            <h3>Insulfilm de segurança para janela</h3>

            <p>O <router-link :to="{ name : 'insulfilmSegurancaJanela' }" title="insulfilm de segurança para janela">insulfilm de segurança para janela</router-link> torna o seu vidro mais resistente a danos e mais forte, evita danos e acidentes familiares e protege os seus bens e entes queridos.</p>

            <div @click="index = 1" class="page-photo-container">
              <Photo
                :src="photos[1].thumb"
                :title="photos[1].title"
                :description="photos[1].description"
                :idLocation="photos[1].idLocation"
              />
            </div>

            <h3>Insulfilm decorativo para janela</h3>

            <p>Os <router-link :to="{ name : 'insulfilmDecorativoJanela' }" title="insulfilmes decorativos para janela">insulfilmes decorativos para janela</router-link> dão privacidade ao local,  proporcionam a sensação de conforto, segurança, e inovam os ambientes.</p>
            
            <div @click="index = 2" class="page-photo-container">
              <Photo
                :src="photos[2].thumb"
                :title="photos[2].title"
                :description="photos[2].description"
                :idLocation="photos[2].idLocation"
              />
            </div>

            <h3>Insulfilm de privacidade para janela</h3>

            <p>O <router-link :to="{ name : 'insulfilmPrivacidadeJanela' }" title="insulfilm de privacidade para janela">insulfilm de privacidade para janela</router-link> é uma solução simples para fornecer privacidade, segurança e beleza.</p>

            <div @click="index = 3" class="page-photo-container">
              <Photo
                :src="photos[3].thumb"
                :title="photos[3].title"
                :description="photos[3].description"
                :idLocation="photos[3].idLocation"
              />
            </div>
            
          </div>
          <!-- col end -->

          <div class="col-lg-5 section-content-illustration">
            <QuotationCall position="float" />
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
    </section>
  </div>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";
import Photo from "@/components/photo/photo.vue";

//Lazy Loading
const QuotationCall = () =>
  import(
    /* webpackChunkName: "QuotationCall" */ "@/components/cotacao/quotation-call.vue"
  );

import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  components: {
    QuotationCall,
    Photo,
    CoolLightBox,
  },

  data() {
    return {
      index: null,
      photos: [

        //refletivo
        {
          description: "Película prata instalada nas janelas da TAM para fornecer privacidade para o ambiente",
          idLocation: 1,
          src: "/images/fotos/tam/MRO-TAM-PRATA-EXTERNO-16.JPG",
          thumb: "/images/fotos/tam/small/MRO-TAM-PRATA-EXTERNO-16.JPG",
          title: "Película para Janela"
        },
        /* {
          description: "Película fumê invertido na C&C em Ribeirão Preto para reduzir o carlor e luminosidade",
          idLocation: 1,
          src: "/images/fotos/cec/fume-invertido-1.JPG",
          thumb: "/images/fotos/cec/small/fume-invertido-1.JPG",
          title: "Película para Janela",
        },
        {
          description: "Conforto térmico e diminuição dos reflexos em espelhos e monitores na Smart Fit Diadema",
          idLocation: 1,
          src: "/images/fotos/smartfit-diadema/DSC05051.JPG",
          thumb: "/images/fotos/smartfit-diadema/small/DSC05051.JPG",
          title: "Película para Janela",
        },
        {
          description: "Insulfilm azul refletivo para proteção solar e decoração das janelas da empresa",
          idLocation: 1,
          src: "/images/fotos/via-sao-paulo/100_1048.jpg",
          thumb: "/images/fotos/via-sao-paulo/small/100_1048.jpg",
          title: "Película para Janela",
        }, */

        //fumes
        /* {
          description: "Filme G5 aplicado no SENAC para escurecer e proteger o ambiente dos raios solares",
          idLocation: 1,
          src: "/images/fotos/senac/DSC01344.JPG",
          thumb: "/images/fotos/senac/small/DSC01344.JPG",
          title: "Película para Janela",
        },
        {
          description: "Aplicada nas janelas do SESC Belenzinho para eliminar o reflexo do painel eletrônico interativo",
          idLocation: 1,
          src: "/images/fotos/sesc/100_1108.jpg",
          thumb: "/images/fotos/sesc/small/100_1108.jpg",
          title: "Película para Janela",
        }, */
        //transparente
        /* {
          description: "Película transparente aplicada na Smart Fit - SP Market para proporcionar conforto térmico",
          idLocation: 1,
          src: "/images/fotos/smartfit-sp-market/DSC05160.JPG",
          thumb: "/images/fotos/smartfit-sp-market/small/DSC05160.JPG",
          title: "Película para Janela",
        }, */

        //segurança
        {
          description: "Película de segurança usado para evitar que o vidro se quebre",
          idLocation: 1,
          src: "/images/fotos/seguranca/pelicula-seguranca-vidros.jpg",
          thumb: "/images/fotos/seguranca/pelicula-seguranca-vidros.jpg",
          title: "Película para Janela",
        },
        //decorativo
        {
          description: "Aplicação de película Mini Blind para decorar as divisórias de vidro",
          idLocation: 6,
          src: "/images/fotos/condominio-mini-blind/mini-blind-3.JPG",
          thumb: "/images/fotos/condominio-mini-blind/small/mini-blind-3.JPG",
          title: "Película para divisória",
        },

        //privacidade
/*         {
          description: "Barrar a entrada de raios solares e forncecer privacidade no Bioterismo da USP",
          idLocation: 1,
          src: "/images/fotos/bioterismo-usp/DSC00389.JPG",
          thumb: "/images/fotos/bioterismo-usp/small/DSC00389.JPG",
          title: "Película para Janela",
        }, */
        {
          description: "Insulfilm branco jateado aplicado na janela para fornecer privacidade ao local",
          idLocation: 1,
          src: "/images/fotos/condominio-branco-jateado/branco-jateado-7.JPG",
          thumb: "/images/fotos/condominio-branco-jateado/small/branco-jateado-7.JPG",
          title: "Película para Janela",
        },
      ],
    }
  },

  mounted: function () {
    //console.log(this.photos)
  },

  created() {
    this.$emit(`update:layout`, LayoutInternal);
    this.$emit(`update:title`, "Insulfilm para Janela");
    this.$emit(
      `update:introduction`,
      "Instalamos insulfilm para janela em comércios, sacadas, escritórios, apartamentos, portarias e na arquitetura em geral"
    );
    this.$emit(`update:bgHeader`, "black");
  },
};
</script>

<style lang="scss" scoped>
</style>