import { render, staticRenderFns } from "./insulfilm-janela.vue?vue&type=template&id=6ec7d7ba&scoped=true&"
import script from "./insulfilm-janela.vue?vue&type=script&lang=js&"
export * from "./insulfilm-janela.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ec7d7ba",
  null
  
)

export default component.exports